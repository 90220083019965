import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { getTokenOrRefresh } from './token_util';
import './custom.css'
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

export default function App() { 
    const [displayText, setDisplayText] = useState('INITIALIZED: ready to test speech...');
    const [player, updatePlayer] = useState({p: undefined, muted: false});

    async function sttFromMic(retryCount) {
        speechsdk.Diagnostics.SetLoggingLevel(speechsdk.LogLevel.Debug);
        speechsdk.Diagnostics.StartConsoleOutput();
        const tokenObj = await getTokenOrRefresh();
        // const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        const speechConfig = speechsdk.SpeechConfig.fromSubscription("ed24683843294c84b4e4a761ddb2529f","eastasia");
        speechConfig.speechRecognitionLanguage = 'zh-CN';
        
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

        setDisplayText('speak into your microphone...');

        // try {
        //     recognizer.recognizeOnceAsync(result => {
        //         if (result.reason === ResultReason.RecognizedSpeech) {
        //             setDisplayText(`RECOGNIZED: Text=${result.text}`);
        //         } else {
        //             setDisplayText('ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.'+result.reason);
        //         }
        //     });
        // } catch (error) {
        //     setDisplayText(error.name+"###"+error.message);
        // }
        let resultText = "";
        // recognizer.startContinuousRecognitionAsync();
        // recognizer.startContinuousRecognitionAsync(
        //     function() {
        //       // 这是成功回调
        //       console.log("Continuous recognition started successfully");
        //     },
        //     function(error) {
        //       // 这是错误回调
        //       console.error("Error in continuous recognition:", error);
        //     }
        // );
        recognizer.sessionStarted = (s, e) => {
            resultText = "Session ID: " + e.sessionId;

            // this.setState({
            //     displayText: resultText
            // });
            setDisplayText(resultText);
        };

        // recognizer.recognizing = (s, e) => {

        // };

        recognizer.recognized = (s, e) => {
            if(e.result.reason === ResultReason.RecognizedSpeech){
                resultText += `\n${e.result.text}` 
            }
            else if (e.result.reason === ResultReason.NoMatch) {
                resultText += `\nNo Match` 
            }

            // this.setState({
            //     displayText: resultText
            // });
            setDisplayText(resultText);
        };

        

        return new Promise((resolve, reject) => {
            // recognizer.startContinuousRecognitionAsync(
            //     () => {
            //         console.log("Continuous recognition started successfully");
                    
            //         // 设置一个检查点，如果在10秒内没有收到任何识别结果，就重试
            //         setTimeout(() => {
            //             if (retryCount < 1) {
            //                 console.log(`Recognition not working, retrying... (attempt ${retryCount + 1})`);
            //                 sttFromMic(retryCount + 1);
            //                 // recognizer.stopContinuousRecognitionAsync(() => {
            //                 //     recognizer.close();
            //                 // }, (err) => {
            //                 //     console.error("Error stopping recognition for retry:", err);
            //                 // });
            //             }
            //         }, 3000);

            //         // 设置30秒后自动停止识别
            //         // setTimeout(() => {
            //         //     recognizer.stopContinuousRecognitionAsync(() => {
            //         //         console.log("Continuous recognition stopped.");
            //         //         recognizer.close();
            //         //         resolve();
            //         //     }, (err) => {
            //         //         console.error("Error stopping recognition:", err);
            //         //         recognizer.close();
            //         //         reject(err);
            //         //     });
            //         // }, 30000);
            //     },
            //     (error) => {
            //         console.error("Error in continuous recognition:", error);
            //         recognizer.close();
            //         reject(error);
            //     }
            // );
            setTimeout(() => {
                recognizer.startContinuousRecognitionAsync();
            }, 3000);
        });

        
        
    }

    async function textToSpeech() {
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        const myPlayer = new speechsdk.SpeakerAudioDestination();
        updatePlayer(p => {p.p = myPlayer; return p;});
        const audioConfig = speechsdk.AudioConfig.fromSpeakerOutput(player.p);

        let synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);

        const textToSpeak = 'This is an example of speech synthesis for a long passage of text. Pressing the mute button should pause/resume the audio output.';
        setDisplayText(`speaking text: ${textToSpeak}...`);
        synthesizer.speakTextAsync(
        textToSpeak,
        result => {
            let text;
            if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
                text = `synthesis finished for "${textToSpeak}".\n`
            } else if (result.reason === speechsdk.ResultReason.Canceled) {
                text = `synthesis failed. Error detail: ${result.errorDetails}.\n`
            }
            synthesizer.close();
            synthesizer = undefined;
            setDisplayText(text);
        },
        function (err) {
            setDisplayText(`Error: ${err}.\n`);

            synthesizer.close();
            synthesizer = undefined;
        });
    }

    async function handleMute() {
        updatePlayer(p => { 
            if (!p.muted) {
                p.p.pause();
                return {p: p.p, muted: true}; 
            } else {
                p.p.resume();
                return {p: p.p, muted: false}; 
            }
        });
    }

    async function fileChange(event) {
        const audioFile = event.target.files[0];
        console.log(audioFile);
        const fileInfo = audioFile.name + ` size=${audioFile.size} bytes `;

        setDisplayText(fileInfo);

        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';

        const audioConfig = speechsdk.AudioConfig.fromWavFileInput(audioFile);
        const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

        recognizer.recognizeOnceAsync(result => {
            let text;
            if (result.reason === ResultReason.RecognizedSpeech) {
                text = `RECOGNIZED: Text=${result.text}`
            } else {
                text = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
            }

            setDisplayText(fileInfo + text);
        });
    }

    return (
        <Container className="app-container">
            <h1 className="display-4 mb-3">Speech sample app</h1>

            <div className="row main-container">
                <div className="col-6">
                    <i className="fas fa-microphone fa-lg mr-2" onClick={() => sttFromMic(0)}></i>
                    Convert speech to text from your mic.

                    

                </div>
                <div className="col-6 output-display rounded">
                    <code>{displayText}</code>
                </div>
            </div>
        </Container>
    );
}