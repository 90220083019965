import axios from 'axios';
import Cookie from 'universal-cookie';

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');

    if (speechToken === undefined) {
        try {
            const res = await axios.get('https://speech-server.aifunbox.com/api/get-speech-token');
            const token = res.data.token;
            const region = res.data.region;
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});

            console.log('Token fetched from back-end: ' + token);
            return { authToken: token, region: region };
        } catch (err) {
            console.log(err.response.data);
            return { authToken: null, error: err.response.data };
        }


        // try {
        //     const speechKey = "cb9134fa28c0419280a25d75f2d267fd";
        //     const speechRegion = "southeastasia";

        //     if (speechKey === 'paste-your-speech-key-here' || speechRegion === 'paste-your-speech-region-here') {
        //         // res.status(400).send('You forgot to add your speech key or region to the .env file.');
        //         console.log("未找到凭证");
        //     } else {
        //         const headers = { 
        //             headers: {
        //                 'Ocp-Apim-Subscription-Key': speechKey,
        //                 'Content-Type': 'application/x-www-form-urlencoded'
        //             }
        //         };

        //         try {
        //             const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        //             const tokenResponse = await axios.post(proxyUrl+`https://${speechRegion}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, null, headers);
        //             // res.send({ token: tokenResponse.data, region: speechRegion });
        //             const token = tokenResponse.data;
        //             const region = speechRegion;
        //             cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});
        //             return { authToken: token, region: region };
        //         } catch (err) {
        //             console.log('There was an error authorizing your speech key.');
        //         }
        //     }
        // } catch (err) {
        //     console.log(err.response.data);
        //     return { authToken: null, error: err.response.data };
        // }

        

    } else {
        console.log('Token fetched from cookie: ' + speechToken);
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}